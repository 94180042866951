/*----------- ROBOTO -----------------------*/
@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url("../public/fonts/roboto/RobotoRegular.ttf") format("truetype");
}


@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url("../public/fonts/roboto/RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
    url("../public/fonts/roboto/RobotoLight.ttf") format("truetype");
}
/*--------------------------------------------------*/


/*----------- SEGOE UI -----------------------*/
@font-face {
  font-family: "SegoeUIRegular";
  src: local("SegoeUIRegular"),
    url("../public/fonts/segoeUI/SegoeUIRegular.ttf") format("truetype");
}

@font-face {
  font-family: "SegoeUIItalic";
  src: local("SegoeUIItalic"),
    url("../public/fonts/segoeUI/SegoeUIItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SegoeUIBoldItalic";
  src: local("SegoeUIBoldItalic"),
    url("../public/fonts/segoeUI/SegoeUIBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SegoeUIBold";
  src: local("SegoeUIBold"),
    url("../public/fonts/segoeUI/SegoeUIBold.ttf") format("truetype");
}

@font-face {
  font-family: "SegoeUILight";
  src: local("SegoeUILight"),
    url("../public/fonts/segoeUI/SegoeUILight.ttf") format("truetype");
}
/*--------------------------------------------------*/
