div.menuHolder {
  user-select: none;
  -moz-user-select: none;
  position: relative;
}

svg.icons {
  display: none;
}

svg.menu {
  position: absolute;
  overflow: visible;
  transition: 0.2s;
  transition-timing-function: ease-out;
}

svg.menu.inner {
  transform: scale(0.66) rotate(-10deg);
  opacity: 0;
  visibility: hidden;
}

svg.menu.outer {
  transform: scale(1.5) rotate(10deg);
  opacity: 0;
  visibility: hidden;
}

/* svg.menu > g > path {
  fill: #5f87b742;
} */

svg.menu > g.sector > path {
  cursor: pointer;
  /*transition: 0.1s;*/
}

svg.menu > g.sector > text,
svg.menu > g.sector > use {
  cursor: pointer;
  fill: white;
}

svg.menu > g.sector:hover > path {
  fill: #5f87b782;
}

/* svg.menu > g.sector.selected > path {
  fill: #5f87b782 !important;
} */

/* svg.menu > g.center:hover > circle {
  fill: #F9A602D0;
} */

svg.menu > g.center > circle {
  cursor: pointer;
  fill: #5f87b732;
}

svg.menu > g.center > text,
svg.menu > g.center > use {
  cursor: pointer;
  fill: white;
}

svg.menu > g.sector  {
  transition: 0.25s;
}
