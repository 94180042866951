body {
  display: flex;
  height: 100%;
  margin: 0;
  font-family: 'SegoeUIRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

code {
  font-family: 'SegoeUIRegular';
}

